import ButtonAction from "../../components/Button/ButtonAction";
import ButtonOnOffSound from "../../components/Button/ButtonOnOffSound";
import React, { Fragment } from "react";
import ImgPendding from "../../assets/status-pending.png";
import ImgRecieved from "../../assets/file.png";
import ImgCooking from "../../assets/cooking.png";
import ImgReadyShip from "../../assets/ready_ship.png";
import ImgShipping from "../../assets/status-shipping.png";
import ButtonSubmit from "../../components/Button/ButtonSubmit";
import { columns } from "./columns";
import TotalOder from "../../components/TotalOder";
import ButtonTime from "../../components/Button/ButtonTime";
import Icon from "../../components/Button/Icon";
import { Button, Spin, Row, Table, Tag } from "antd";
import moment from "moment";
import {
  convertOrderType,
  translatePaymentMethod,
} from "utils/CommomFunction";
import { useTranslation } from "react-i18next";

export const renderButtonFunction = (
  dataOrderModal,
  showModalCancel = () => {},
  handleOnOffSound = () => {},
  isOnAppSound
) => {
  let content;
  let color;
  switch (dataOrderModal?.status) {
    case "pending":
      break;
    case "received":
      content = "HỦY ĐƠN HÀNG";
      color = "#E31837";
      break;
    case "cooking":
      content = "HỦY ĐƠN HÀNG";
      color = "#E31837";
      break;
    case "ready_to_ship":
      content = "HỦY ĐƠN HÀNG";
      color = "#E31837";
      break;
    default:
      break;
  }

  if (
    (dataOrderModal?.status == "shipping" ||
      dataOrderModal?.status == "bom_request" ||
      dataOrderModal?.status == "arrived") &&
    isOnAppSound
  ) {
    return <ButtonOnOffSound onClick={handleOnOffSound} />;
  }

  return (
    content && (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
        key="btn1"
      >
        {" "}
        {isOnAppSound && <ButtonOnOffSound onClick={handleOnOffSound} />}{" "}
        <ButtonAction title={content} color={color} onClick={showModalCancel} />{" "}
      </div>
    )
  );
};

export const renderImageTitle = (status) => {
  let img;
  switch (status) {
    case "pending":
      img = ImgPendding;
      break;
    case "sent":
      img = ImgRecieved;
      break;
    case "cooking":
      img = ImgCooking;
      break;
    case "ready":
      img = ImgReadyShip;
      break;
    case 5:
    case "shipping":
      img = ImgShipping;
      break;
    default:
      break;
  }
  return img && <img src={img} alt=""></img>;
};

export const renderFooterReadyToShip = (
  dataOrderModal,
  loadingButton,
  loadingButtonTop,
  handleSubmitBom,
  handleSubmitShipping,
  handleSubmitCompletePickUp
) => {
  const { t } = useTranslation();
  if (dataOrderModal?.shipping_method === "Giao hàng tận nơi") {
    return [
      <span
        onClick={() => handleSubmitShipping(dataOrderModal?.id)}
        key={dataOrderModal?.id}
      >
        <ButtonSubmit
          loadingButton={loadingButton}
          title={t("shipping")}
          key={4}
          color="#00B77F"
        />
      </span>,
    ];
  }
  return [
    <div className="btn-gr" key={dataOrderModal?.id}>
      <Button
        block
        loading={loadingButton}
        disabled={loadingButtonTop}
        onClick={() => handleSubmitBom(dataOrderModal)}
        style={{ backgroundColor: "#E31837" }}
        className="btn-left btn-confirm"
      >
        {!loadingButton && t("confirmBomOrder")}
      </Button>

      <Button
        block
        loading={loadingButtonTop}
        disabled={loadingButton}
        onClick={handleSubmitCompletePickUp}
        className="btn-right btn-confirm"
        style={{ marginLeft: 0 }}
      >
        {!loadingButtonTop && t("confirmFinishOrder")}
      </Button>
    </div>,
  ];
};

export const renderFooterShipping = (
  dataOrderModal,
  loadingButton,
  handleSubmitBom,
  handleSubmitComplete
) => {
  const { t } = useTranslation();
  return [
    <div className="btn-gr" style={{ marginRight: "10px" }}>
      <Button
        block
        loading={loadingButton}
        style={{ width: "100%" }}
        onClick={handleSubmitComplete}
        className="btn-right btn-confirm"
      >
        {t("confirmFinishOrder")}
      </Button>
    </div>,
  ];
};

export const renderStatus = (dataOrderModal) => {
  let color;
  let content;
  switch (dataOrderModal?.status) {
    case "pending":
      color = "#FFC522";
      content = "CHỜ XỬ LÝ";
      break;
    case "received":
      color = "#0A8D87";
      content = "ĐÃ NHẬN";
      break;
    case "cooking":
      color = "#F0810D";
      content = "ĐANG NẤU";
      break;
    case "ready_to_ship":
      color = "#3FB4C3";
      content = "SẴN SÀNG GIAO";
      break;
    case "shipping":
      color = "#00B77F";
      content = "ĐANG GIAO";
      break;
    case "bom_request":
      color = "#E31837";
      content = "BOM";
      break;
    case "arrived":
      color = "#00B77F";
      content = "ĐÃ ĐẾN";
      break;
    default:
      break;
  }
  return <Tag color={color}>{content}</Tag>;
};

export const renderSecondButton = (dataOrderModal) => {
  let content;
  let color;
  switch (dataOrderModal?.status) {
    case "ready_to_ship":
      content = "THAY ĐỔI SHIPPER";
      color = "#484848";
      break;
    default:
      break;
  }
  return content && <ButtonAction title={content} color={color} />;
};

const timeToEnd = (dataOrderModal) => {
  if (dataOrderModal?.pickup_date && dataOrderModal?.pickup_time) {
    const time = `${moment(dataOrderModal?.pickup_date, [
      "YYYY-MM-DD HH:mm:ss",
    ]).format("MM/DD/YYYY")} ${dataOrderModal?.pickup_time}`;
    return time;
  }

  return dataOrderModal?.shipping_method == "Giao hàng tận nơi"
    ? moment(dataOrderModal?.created_at)
        .add(Number(dataOrderModal?.delivery_time), "m")
        .format("hh:mm A")
    : moment(dataOrderModal?.ship_date).format("hh:mm A");
};

const convertDetailOrder = (detailOrder) => {
  if (detailOrder) {
    let items = detailOrder?.items ?? [];

    items = items.sort(function (a, b) {
      let c = a?.price;
      let d = b?.price;
      return c - d;
    });
    return items?.map((item, index) => ({
      ...item,
      key: index,
      name: item?.pos_name ?? item?.name,
      count: item?.qty,
      price: item?.price + "đ",
      total: item?.price + "đ",
    }));
  }
  return [];
};

export const renderModalData = (
  detailOrder,
  dataOrderModal,
  openModalDelivered,
  width = 620
) => {
  const { t } = useTranslation();
  return detailOrder && detailOrder.id === dataOrderModal?.id ? (
    <>
      <div className="modal-info" key={dataOrderModal?.id}>
        <div className="modal-info-left">
          {detailOrder?.shipping_method === "freeshipping_freeshipping" && (
            <>
              <div className="modal-info-left-text" style={{ width: "100%" }}>
                <Icon status={true} />
                <div className="modal-info-desc" style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span>
                      <strong>{t("orderMethod")}</strong> <br />
                      {t("delivery")}
                    </span>{" "}
                    <ButtonTime dataOrder={detailOrder} />
                  </div>
                  <span>
                    {`${t("paymentMethod")}: `}
                    <span style={{ fontWeight: "bold", color: "#0A8D87" }}>
                      {translatePaymentMethod(detailOrder?.payment_method)}
                    </span>
                  </span>
                  <br />
                  <strong>
                    {t("chanelBooking")}:{" "}
                    {convertOrderType(detailOrder?.order_type)}
                  </strong>
                  <p className="modal-info-time">
                    {t("receivedExp")}:{" "}
                    {!(
                      dataOrderModal?.pickup_date && dataOrderModal?.pickup_time
                    ) && timeToEnd(dataOrderModal)}
                  </p>
                  <p className="modal-info-time">
                    {dataOrderModal?.pickup_date &&
                      dataOrderModal?.pickup_time &&
                      timeToEnd(dataOrderModal)}
                  </p>
                  {(detailOrder?.status === "shipping" ||
                    detailOrder?.status === "arrived") && (
                    <Row>
                      <p
                        className="modal-info-time"
                        style={{ marginRight: "70px" }}
                      >
                        {t("deliveredAt")}:
                      </p>
                    </Row>
                  )}
                </div>
              </div>
            </>
          )}
          {detailOrder?.shipping_method === "mageworxpickup_mageworxpickup" && (
            <>
              <div className="modal-info-left-text" style={{ width: "100%" }}>
                <Icon status={false} />
                <div className="modal-info-desc" style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p>{t("orderMethod")}</p>
                    <ButtonTime dataOrder={detailOrder} />
                  </div>
                  <span>{t("storePickup")}</span>
                  <br />
                  <span>
                    {`${t("paymentMethod")}: `}
                    <span style={{ fontWeight: "bold", color: "#0A8D87" }}>
                      {translatePaymentMethod(detailOrder?.payment_method)}
                    </span>
                  </span>
                  <br />
                  <strong>
                    {`${t("chanelBooking")}: `}:{" "}
                    {convertOrderType(detailOrder?.order_type)}
                  </strong>
                  <p className="modal-info-time">
                    {`${t("receivedExp")}: `}:{" "}
                    {!(
                      dataOrderModal?.pickup_date && dataOrderModal?.pickup_time
                    ) && timeToEnd(dataOrderModal)}
                  </p>
                  <p className="modal-info-time">
                    {dataOrderModal?.pickup_date &&
                      dataOrderModal?.pickup_time &&
                      timeToEnd(dataOrderModal)}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="modal-info-right"
          style={{ overflow: "scroll", maxHeight: 115 }}
        >
          <h5>
            {`${detailOrder?.firstname} ${detailOrder?.lastname}`} -{" "}
            {detailOrder?.customer_phone}
          </h5>
          <p>
            {detailOrder.shipping_method === "Nhận tại cửa hàng" ? (
              <b>{t("storePickup")}</b>
            ) : (
              <>
                <b>{t("deliveryTo")} :</b> {detailOrder?.phone} -{" "}
                <span>{detailOrder?.address}</span>
                {detailOrder?.address_note && (
                  <Fragment>
                    <br />
                    <b style={{ color: "#E31837" }}>{`Ghi chú: `}</b>
                    <span style={{ color: "#E31837" }}>
                      {detailOrder?.address_note}
                    </span>
                  </Fragment>
                )}
                {detailOrder?.code_card && (
                  <Fragment>
                    <br />
                    <b style={{ color: "#E31837" }}>{`Mã thẻ tích điểm: `}</b>
                    <span style={{ color: "#E31837" }}>
                      {detailOrder?.code_card}
                    </span>
                  </Fragment>
                )}{" "}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="modal-remark">
        {detailOrder?.use_plastic !== 0 && (
          <h3>
            <span>{detailOrder?.use_plastic !== 0 && t("plasticEating")}</span>
          </h3>
        )}
        <h5 style={{ maxHeight: 30, overflowY: "scroll", color: "#E31837" }}>
          <b>{t("note")}:</b> {detailOrder?.note?.split("&&")[0]}
        </h5>
        <h5 style={{ maxHeight: 30, overflowY: "scroll", color: "#E31837" }}>
          {" "}
          {detailOrder?.note?.split("&&")[1]}
        </h5>
      </div>
      <div className="modal-order">
        <Table
          className="table-order"
          columns={columns}
          dataSource={convertDetailOrder(detailOrder)}
          pagination={false}
          scroll={{ y: 340 }}
          summary={() => {
            return <TotalOder detailOrder={detailOrder} width={width} />;
          }}
        />
      </div>
    </>
  ) : (
    <Row justify="center">
      <Spin spinning></Spin>
    </Row>
  );
};
