/* eslint-disable no-undef */
import React, { useState } from "react";
import { Drawer } from "antd";
import { RenderItem, RenderLogout, Header } from "./component";
// import { useNavigate } from 'react-router-dom';
import { ConfirmLogoutModal } from "../Modal/ModalConfirmLogout";
// import MenuManagerIcon from '../../assets/icons/menuManagerIcon';
// import CustomerIcon from '../../assets/icons/customerIcon';
import SettingIcon from "../../assets/icons/setting";
import { useHistory } from "react-router-dom";
import BoardMenuIcon from "assets/icons/BoardMenuIcon";
import PrinterIcon from "assets/icons/printerIcon";

const BASE_ROUTER = {
  Menu: "/menu",
  HOME: "/",
  KITCHEN: "/setting_kitchen",
  DEVICE: "/setting_device",
};

export default function DrawerMenu() {
  const [open, setOpen] = useState(false);
  // const { isLightMode, toggleMode } = useContext(ThemeContext);
  // const navigation = useNavigate();
  const history = useHistory();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const MenuData = [
    {
      title: "Setting",
      icon: <SettingIcon />,
      to: BASE_ROUTER.KITCHEN,
      isGo: true,
    },
    {
      title: "Device",
      icon: <PrinterIcon color="#1C2433" backgroundColor="#1c243366" />,
      to: BASE_ROUTER.DEVICE,
      isGo: true,
    },
  ];
  const [isShowConfirmLogout, setIsShowConfirmLogout] = useState(false);
  const onLogout = async () => {
    setIsShowConfirmLogout(true);
  };
  return (
    <>
      <ConfirmLogoutModal
        isShowConfirmLogout={isShowConfirmLogout}
        closeModalConfirmLogout={() => setIsShowConfirmLogout(false)}
      />
      <div
        style={{
          backgroundColor: "#FF9D00",
          height: 72,
          width: 72,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={showDrawer}
      >
        <BoardMenuIcon color="white" />
      </div>

      <Drawer
        title="Basic Drawer"
        onClose={onClose}
        open={open}
        bodyStyle={{
          background: "var(--form-background)",
          padding: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        headerStyle={{ display: "none" }}
      >
        <div style={{ flex: 1, overflow: "auto" }}>
          <Header
            onClose={onClose}
            onLogo={() => {
              history.push(BASE_ROUTER.HOME);
            }}
          />
          <div style={{
            marginTop: 16
          }}>
            {MenuData.map((item, index) => (
              <RenderItem
                key={index}
                icon={item.icon}
                title={item.title}
                onPress={() => {
                  history.push(item.to);
                }}
                color={"var(--text-primary)"}
              />
            ))}
          </div>
        </div>
        <RenderLogout onPress={onLogout} />
      </Drawer>
    </>
  );
}
