import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { getLocalStorage } from "utils/CommomFunction";
import { HomePage, PageLogin } from "./pages";
import NotFound from "./pages/404/NotFound";
import Maintenance from "./pages/404/Maintenance";
import ShipperPage from "pages/Shipper";
import OrderHistory from "pages/OrderHistory";
import SettingKitchen from "pages/SettingKitchen";
import SettingDevice from "pages/SettingDevice";

import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { emitter } from "apis/apiWithCancel";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const MyRoutes = () => {
  const query = useQuery();
  const token = query.get("token");
  const isLogin = () => {
    return getLocalStorage("access_token");
  };
  useEffect(() => {
    if (token) {
      localStorage.setItem("access_token", token);
      localStorage.setItem("merchantAccount", true);
      // navigation.push(pathname);
    }
  }, [token]);
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLocalStorage("i18nextLng") || "en");

    const handleMessage = (event) => {
      if (!window?.ReactNativeWebView) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data?.action === "getPrinter") {
          notification.success({
            message: "Get printer information successfully",
            description: data.data.deviceName,
          });
          emitter.emit("printerSelect", JSON.stringify(data?.data));
          return;
        }
        localStorage.setItem("printer_data", JSON.stringify(data?.data));
        emitter.emit("printerSelect_Flow2", JSON.stringify(data?.data));
      } catch (error) {
        console.log("error");
      }
    };

    window.addEventListener("message", handleMessage);
    document.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
      document.removeEventListener("message", handleMessage);
      localStorage.removeItem("printer_data");
    };
  }, []);
  return (
    <div>
      <Switch>
        <Route exact path="/login">
          {isLogin() ? <Redirect to="/home" /> : <PageLogin />}
        </Route>
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/history">
          {isLogin() ? <OrderHistory /> : <PageLogin />}
        </Route>
        <Route exact path="/setting_kitchen">
          <SettingKitchen />
        </Route>
        <Route exact path="/setting_device">
          <SettingDevice />
        </Route>
        <Route exact path="/shipper">
          {isLogin() ? <ShipperPage /> : <PageLogin />}
        </Route>
        <Route exact path="/">
          {isLogin() ? <Redirect to="/home" /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/notfound" component={NotFound}></Route>
        <Route exact path="/maintenance" component={Maintenance}></Route>
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
};
