import React from "react";
import PrinterGrid from "./PrinterGrid";
import Container from "layouts/Container";
export default function PrinterDevice() {
  return (
    <Container>
      <PrinterGrid />
    </Container>
  );
}
