import React from "react";
import nextIcon from "./next_v2.png";
export default function ButtonChangeStatusItem({ type, onClick, onServed }) {
  return type === "sent" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginLeft: 20,
        minWidth: 40,
      }}
      onClick={onClick}
    >
      <img src={nextIcon} alt="nextIcon" style={{ width: 40, height: 40 }} />
    </div>
  ) : type === "cooking" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginLeft: 20,
      }}
      onClick={onClick}
    >
      <img src={nextIcon} alt="nextIcon" style={{ width: 40, height: 40 }} />
    </div>
  ) : (
    <div
    // style={{
    //   display: "flex",
    //   flexDirection: "row",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   cursor: "pointer",
    //   background: "#198754",
    //   borderRadius: 4,
    //   paddingBlock: 6,
    //   marginLeft: 20,
    //   paddingInline: 12,
    // }}
    // onClick={onServed}
    >
      {/* <img src={foodIcon} alt="potIcon" style={{ width: 17, height: 17 }} /> */}
    </div>
  );
}
