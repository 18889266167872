import React, { useEffect, useState } from "react";
import PrinterCard from "./PrinterCard";
import AddPrinterCard from "./AddPrinterCard";
import icon_back from "../../assets/icons/back.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { emitter } from "apis/apiWithCancel";

const PrinterGrid = () => {
  const history = useHistory();
  const [printerData, setPrinter] = useState();

  useEffect(() => {
    const printer = localStorage.getItem("printer_data");
    if (printer) {
      try {
        const data = JSON.parse(printer);
        setPrinter(data);
      } catch (error) {
        console.log(error);
      }
    }
    emitter.on("printerSelect_Flow2", (event) => {
      const data = JSON.parse(event);
      setPrinter(data);
    });
    return () => {
      emitter.off("printerSelect_Flow2");
    };
  }, []);

  return (
    <section
      style={{
        color: " #333",
        background: "#F0F0F0",
        width: "100%",
        height: "100%",
        padding: 16,
      }}
    >
      <div className="header-wrap">
        <img
          style={{ width: 25, height: 25, cursor: "pointer" }}
          src={icon_back}
          alt="ic_back"
          onClick={() => history.push("/home")}
        />
        <p className="settings-title">Printer Device</p>
      </div>
      {printerData ? (
        <PrinterCard
          name={printerData?.deviceName || 'z11'}
          connectionType={printerData?.type || 118110472888}
          isDefault={true}
        />
      ) : (
        <AddPrinterCard />
      )}
    </section>
  );
};

// const GridContainer = styled.section`
//   display: flex;
//   max-width: 993px;
//   align-items: center;
//   gap: 40px 43px;
//   justify-content: flex-start;
//   flex-wrap: wrap;
// `;

export default PrinterGrid;
