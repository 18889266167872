import Loader from "react-spinners/PulseLoader";
import "./index.scss";

export default function CustomButton({
  title = "",
  onClick,
  width = 50,
  height = 100,
  fontSize = 14,
  fontWeight = 700,
  className,
  style,
  isHover = true,
  type = "button",
  isButtonTransparent = false,
  isLoading = false,
  loadingColor,
  renderIcon = null,
}) {
  const _className = isButtonTransparent
    ? `${"custom_button_transparent"} ${className ? className : ""} ${
        isHover ? "custom_button_transparent_hover" : ""
      }`
    : `${"custom_button"} ${className ? className : ""} ${
        isHover && !isLoading ? "custom_button_hover" : ""
      }`;

  return (
    <button
      type={type}
      style={{
        width,
        height,
        fontSize,
        fontWeight,
        borderRadius: 8,
        ...(isLoading && { opacity: 0.75, cursor: "unset" }),
        ...(style && { ...style }),
      }}
      disabled={isLoading}
      onClick={onClick}
      className={_className}
    >
      {isLoading ? (
        <Loader
          color={loadingColor ?? "#fff"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {renderIcon ? renderIcon() : null}
          {title}
        </div>
      )}
    </button>
  );
}
