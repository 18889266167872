import React from "react";
import imageSrc from "./image/printerIcon.svg";

const PrinterCard = ({ name, connectionType, isDefault }) => {
  const OpenMenuPrinter = () => {
    if (window?.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "openMenuPrinter",
        })
      );
    }
  };
  return (
    <div
      onClick={OpenMenuPrinter}
      style={{
        borderRadius: "8px",
        border: "1px solid var(--Green-Prima, #ff9d00)",
        backgroundColor: "#fff",
        width: "216px",
        height: "216px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        justifyContent: "center",
        position: "relative",
        cursor: "pointer",
      }}
    >
      {isDefault && (
        <span
          style={{
            position: "absolute",
            alignSelf: "flex-start",
            borderRadius: "0 8px 0 0",
            backgroundColor: "var(--Green-Prima, #ff9d00)",
            color: "var(--Black-10, #f0f5f9)",
            width: "82px",
            height: "26px",
            top: "20px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            font: "400 16px Montserrat, sans-serif",
            zIndex: 10,
          }}
        >
          Default
        </span>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "-12px",
        }}
      >
        <div>
          <img src={imageSrc} alt={`${name} printer`} />
        </div>
        <div
          style={{
            color: "#000",
            fontWeight: 700,
            marginTop: 16
          }}
        >
          {name}
        </div>
        <div style={{
          marginTop: 16
        }}>{connectionType}</div>
      </div>
    </div>
  );
};

export default PrinterCard;
