import React from "react";
// import styled from "styled-components";
import plusIcon from "./image/plus.svg";
const AddPrinterCard = () => {
  const OpenMenuPrinter = () => {
    if (window?.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "openMenuPrinter",
        })
      );
    }
  };
  return (
    <div
      onClick={OpenMenuPrinter}
      style={{
        borderRadius: "8px",
        border: "1px solid var(--Green-Prima, #ff9d00)",
        backgroundColor: "rgb(255, 255, 255)",
        width: "216px",
        height: "216px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          borderRadius: "8px",
          backgroundColor: "rgb(248, 249, 252)",
          width: "85px",
          height: "85px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={plusIcon} alt="" />
      </div>
      <div
        style={{
          marginTop: "35px",
          color: "rgb(0, 0, 0)",
          textAlign: "center",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "700",
          fontSize: "16px",
        }}
      >
        Add Printer
      </div>
    </div>
  );
};

export default AddPrinterCard;
