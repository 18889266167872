import React from "react";

export default function PrinterIcon(props) {
  return (
    <svg
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill={props?.backgroundColor || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.594 13.6254H31.4065V9.87537C31.4065 6.12537 30.0002 4.25037 25.7815 4.25037H19.219C15.0002 4.25037 13.594 6.12537 13.594 9.87537V13.6254Z"
        stroke={props?.color || "#0AA699"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.0002 28.625V36.125C30.0002 39.875 28.1252 41.75 24.3752 41.75H20.6252C16.8752 41.75 15.0002 39.875 15.0002 36.125V28.625H30.0002Z"
        stroke={props?.color || "#0AA699"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.375 19.2496V28.6246C39.375 32.3746 37.5 34.2496 33.75 34.2496H30V28.6246H15V34.2496H11.25C7.5 34.2496 5.625 32.3746 5.625 28.6246V19.2496C5.625 15.4996 7.5 13.6246 11.25 13.6246H33.75C37.5 13.6246 39.375 15.4996 39.375 19.2496Z"
        stroke={props?.color || "#0AA699"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.8748 28.625H29.606H13.1248"
        stroke={props?.color || "#0AA699"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.1248 21.1254H18.7498"
        stroke={props?.color || "#0AA699"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
